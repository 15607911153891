export function addCustomSelect(elm) {
  const $elm = $(elm);

  if (/Android|iPhone|iPad|webOS|iPod|BlackBerry/i.test(navigator.userAgent)) {
    if ($elm.hasClass("custom-select-homepage")) {
      $elm.addClass("bg-white");
      $elm.addClass("edit");
    }
    $elm.on("change", function (e) {
      const id = e.currentTarget.id || "no_id";
      const text = $elm.find("option:selected").text() || "empty";
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "selectionMade",
        selectedValue: text,
        selectId: id,
      });
    });
    $($elm).wrap("<div></div>");
    return;
  }

  const classes = $elm.attr("class");
  $elm.css("position", "absolute");
  $elm.css("opacity", "0");
  $elm.attr("tabindex", -1);
  let template = '<div class="' + classes + '">';
  template +=
    '<span tabindex="0" class="custom-select-trigger">' +
    ($elm.attr("placeholder") ? $elm.attr("placeholder") : "") +
    "</span>";
  template += '<div class="custom-options">';

  $elm.children("option, optgroup").each(function (i, element) {
    if (!element.value && i === 0) {
      // Add styling to the first element to make it invisible
      template +=
        '<span class="custom-option ' +
        $(elm).attr("class") +
        '" data-value=""' +
        'style="position: absolute; opacity: 0;" >' +
        $(this).html() +
        "</span>";
    } else {
      if (element.nodeName === "OPTGROUP") {
        const children = $(this).children("option");
        template +=
          ' <span tabindex="0" class="custom-optGroup ' +
          $(elm).attr("class") +
          '">';
        children.each(function (_, el) {
          template +=
            ' <span tabindex="0" class="custom-option ' +
            $(el).attr("class") +
            '" data-value="' +
            $(this).attr("value") +
            '">' +
            $(this).html() +
            "</span>";
        });
        template += "</span>";
      } else {
        template +=
          ' <span tabindex="0" class="custom-option ' +
          $(elm).attr("class") +
          '" data-value="' +
          $(this).attr("value") +
          '">' +
          $(this).html() +
          "</span>";
      }
    }
  });

  template += "</div></div>";
  template = $.parseHTML(template, document);

  $(template)
    .find(".custom-select-trigger")
    .on("focus", function (event) {
      $(this).parents(".custom-select").addClass("opened");
      event.stopPropagation();

      $("html").one("mousedown", function (e) {
        if (!$(e.target).hasClass("custom-options")) {
          $(".custom-select").removeClass("opened");
        }
      });

      // const elementToFocus = $(this).next().children().first().get(0);
      const val = $elm.val();
      let selection = $elm
        .parent()
        .find(".custom-options")
        .find('[data-value="' + val + '"]');
      if (val === "") selection = selection.next(); // For custom-selects without standard value
      selection.addClass("selection");
      setTimeout(
        (element) => {
          element.focus();
        },
        200,
        selection
      );

      // event.stopPropagation();
      event.preventDefault();
    });

  $(template)
    .find(".custom-option")
    .on("mousedown", function () {
      // Push new value for mousedown and enter.
      // Putting this in the onChange methode would trigger the push a second time, because a second entry for the summary form is made.
      const id = $(this).parents().children().closest("select[id]").attr("id");
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "selectionMade",
        // selectedValue: $(this).data('value')
        selectedValue: $(this).text(),
        selectId: id,
      });
      $(this)
        .parents(".custom-select-wrapper")
        .find("select")
        .val($(this).data("value"))
        .change()
        .blur();
      $(this).parents(".custom-select").removeClass("opened");
    });

  $(template)
    .find(".custom-option")
    .on("keydown", function (e) {
      // Prevent scrolling for space and arrow keys
      if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
        e.preventDefault();
      }

      switch (e.keyCode) {
        case 38: {
          // arrowkey up
          const nextElementToFocusOn = $(this).prev();
          nextElementToFocusOn.focus();
          if (nextElementToFocusOn.length !== 0)
            $(this).removeClass("selection");
          nextElementToFocusOn.addClass("selection");
          break;
        }
        case 40: {
          // arrowkey down
          const nextElementToFocusOn = $(this).next();
          nextElementToFocusOn.focus();
          if (nextElementToFocusOn.length !== 0)
            $(this).removeClass("selection");
          nextElementToFocusOn.addClass("selection");
          break;
        }
        case 13: {
          // enter
          e.preventDefault();
          const id = $(this)
            .parents()
            .children()
            .closest("select[id]")
            .attr("id");
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "selectionMade",
            // selectedValue: $(this).data('value')
            selectedValue: $(this).text(),
            selectId: id,
          });
          $(this)
            .parents(".custom-select-wrapper")
            .find("select")
            .val($(this).data("value"))
            .change()
            .blur();
          $(this).parents(".custom-select").removeClass("opened");

          // Focus on next element
          const focusables = $(':focusable:not("span, .custom-options")');
          const current = focusables.index(
            $(this).parents(".custom-select").prev()
          );
          let next = focusables.not(".custom-option").eq(current + 1);
          // eslint-disable-next-line eqeqeq
          if (next.attr("tabindex") == -1) {
            // Moving from custom-select to another cs
            next = next.next().children().first();
          }
          next.focus();
          break;
        }
        case 27: {
          // escape
          $(this).parents(".custom-select").removeClass("opened");
          $(this).removeClass("selection");
          break;
        }
        case 9: {
          // tab
          $(this).parents(".custom-select").removeClass("opened");
          $(this).removeClass("selection");
          // Focus on next element
          e.preventDefault();
          const focusables = $(':focusable:not("span, .custom-options")');
          const current = focusables.index(
            $(this).parents(".custom-select").prev()
          );
          const isShiftKeyPressed = e.shiftKey ? -1 : 1;
          let next = focusables
            .not(".custom-option")
            .eq(current + isShiftKeyPressed);
          // eslint-disable-next-line eqeqeq
          if (next.attr("tabindex") == -1) {
            // eslint-disable-next-line eqeqeq
            if (isShiftKeyPressed == -1) {
              next = next
                .parent()
                .children()
                .eq(1)
                .children()
                .first()
                .trigger("focus");
              return;
            } else {
              next = next.next().children().first();
            }
          }
          next.focus();
          break;
        }
        default:
        // for other button presses
      }
    });

  // $elm.wrap('<div class="custom-select-wrapper" id="' + wrapperId + '"></div>');
  $elm.wrap('<div class="custom-select-wrapper"></div>');
  $elm.after(template);
  $elm.on("change", function () {
    const val = $elm.val();
    const selection = $elm
      .parent()
      .find(".custom-options")
      .find('[data-value="' + val + '"]');
    $elm
      .parent()
      .find(".custom-options")
      .find(".custom-option")
      .removeClass("selection");
    selection.addClass("selection");
    $elm
      .parent()
      .find(".custom-select")
      .find(".custom-select-trigger")
      .text(selection.text());
  });
}
