/* eslint-disable no-undef */
function getGBSess() {
  const el = $("#gb");
  if (el.length === 0) return null;
  return el.data("sess") || {};
}

function getGBFeatures() {
  const el = $("#header_gb");
  if (el.length === 0) return null;
  return el.data("features") || {};
}

/*

{
   "event": "pk-form-start",
   "purpose": "freie Verfügung",
   "financingTermInMonths": 84,
   "loanAmount": 8000,
   "monthlyRate": 114,
   "formStepNumber": 1,
   "formStepName": "start",
   "previousFormStepName": "",
   "formVariant": "0.9.1-rc.25",
   "formVersionId": "7b9a775bfe887401053
}

*/

// sends Growthbook experiment data to GTM
export function sendGrowthbookData() {
  const timestamp = Date.now();
  function checkData(single) {
    return (
      single.experiment_id != null &&
      single.variation_id != null &&
      single.variation_name != null
    );
  }
  const data = getGBSess();
  if (data != null && Array.isArray(data)) {
    window.dataLayer = window.dataLayer || [];
    data.forEach((single) => {
      if (checkData(single)) {
        // only when the experiment is not pk-personal-address, we send the data, if it is pk-personal-address, we only send the data when the user is new
        if (
          single.experiment_id !== "pk-personal-address" ||
          localStorage.getItem("welcome_back") == null
        ) {
          window.dataLayer.push({
            ...single,
            timestamp,
            event: "experimentParticipation",
          });
        }
      }
    });
  }

  let pathname = "";
  try {
    pathname = window.location.pathname;
    if (
      pathname.includes("/privatkredit/anfrage/") ||
      pathname.includes("/pk-antrag/anfrage/")
    ) {
      const { amount, monthlyRate, runtime } = window.credit_info;
      const purpose = window?.credit_type || "freie Verfügung";
      const creditFormType = window?.credit_form_type;
      const dataLayer = window.dataLayer || [];
      // We had some issues where the timestamp of the `experimentParticipation` event was the exact same as the `pk-form-start` event, so we add 10ms to the timestamp so that the ordering of the events is correct when they arrive on the server
      setTimeout(() => {
        try {
          const startTimestamp = timestamp + 100;
          if (creditFormType) {
            const formVariant =
              creditFormType === "new" ? REACT_FORM_VERSION : "1.2";
            dataLayer.push({
              event: "pk-form-start",
              timestamp: startTimestamp,
              purpose,
              financingTermInMonths: runtime,
              loanAmount: amount,
              monthlyRate,
              formStepNumber: 1,
              formStepName: "start",
              previousFormStepName: "",
              formVariant,
              formVersionId: VERSION,
            });
          }
        } catch (errror) {
          // nothing
        }
      }, 100);
    }
  } catch (error) {
    // we cant really do anything with the error right now...
  }
}

export function getGBFeature(featureKey, defaultValue = undefined) {
  const features = getGBFeatures();
  if (features != null && features[featureKey] != null) {
    return features[featureKey];
  }
  return defaultValue;
}
