/* eslint-disable no-undef */
export function getSyslocale() {
  try {
    let sysLocale = navigator.language.toLowerCase();
    // sysLocale = "en-us";
    // sysLocale = "de-ch";

    if (numeral.locales[sysLocale] === undefined) {
      sysLocale = sysLocale.slice(0, 2);
    }
    if (!sysLocale) {
      sysLocale = "de";
    }
    if (navigator.platform.indexOf("iPhone") !== -1) {
      // alter delimeter suited for iPhone decimal keyboard
      const decimalDelimiter = Number(1.1).toLocaleString().substring(1, 2);
      const kDelimiter = Number(1000).toLocaleString().substring(1, 2);
      numeral.locales[sysLocale].delimiters = {
        thousands: kDelimiter,
        decimal: decimalDelimiter,
      };
      // Es gibt noch ein Problem, bei iPhone-User wenn zB. die Sprache auf Englisch und die Region auf Deuschland gestellt wird, wird dem User ein Komma als Dezimaltrennzeichen angeboten.
    }
    return sysLocale;
  } catch (e) {
    return navigator.language.toLowerCase();
  }
}
